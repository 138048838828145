body {
    background-color: white;
}

body,
html {
    color: rgba(36, 36, 36, 0.8);
    font-size: 16px;
    font-family: 'Helvetica Neue', 'Helvetica', -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Segoe WP", Ubuntu, sans-serif;
    line-height: 1.5rem;
    font-weight: 400;
    margin: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

h1, 
h2,
h3,
h4,
h6 {
    color: #242424;
    font-family: 'Helvetica Neue', Helvetica, Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", sans-serif;
}

a {
    font-size: 14px;
    line-height: 20px;
    color: rgba(36, 36, 36, 0.8);
    position: relative;
}

a:hover {
    color: rgba(36, 36, 36, 1);
}
